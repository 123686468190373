import React,  {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../App.css'; 
import axiosIns from '../components/axiosIns';
import ProfileCircle from './Profile';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ReactModal from 'react-modal';
import {AiFillCloseCircle} from 'react-icons/ai'


const columns = [
  {
    name: 'Name',
    selector: 'emp_ename',
    sortable: true,
  },
  {
    name : "Patient Name",
    selector : "patient_name",
    sortable : true,
  },
  {
    name: 'Company',
    selector: 'company',
    sortable: 'true'
  },
  {
    name: 'Doctor',
    selector: 'doctor',
    sortable: 'true',
  },
  {
    name : 'Service',
    selector: 'service',
    sortable:'true'
  },
  {
    name : 'Date',
    selector: (param) => new Date(param.date).toLocaleDateString(),
    sortable : true
  },
  // {
  //   name :'Actions',
  //   // selector :'actions',
  //   selector: (param)=>addDropDown(param) 
  // },
];

function Appoinment() {
  const [data, setData] = useState([]);
  // const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [dashboardval, setDashboard] = useState({
    doctor: "",
    company: "",
    employee: "",
    visiter :'',
  })
  const {doctor, company, employee, visiter} = dashboardval;
  const [adsList, setadsList] = useState([]);

  const getDetails = async()=>{
    await axiosIns.post('/dashboard')
    .then((res) => {
      setDashboard(res.data.data)
      console.log(data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const getData = async()=>{
    // axios.defaults.headers.common['Authorization'] = userToken
    await axiosIns.post('/booking_request_list')
    .then((res) => {
      setData(res.data.data)
      setLoading(false)
      console.log(data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const getAds = async()=>{
    await axiosIns.post('/ads_list')
    .then((res) => {
      setadsList(res.data.data)
      console.log(data)
    }).catch((err)=>{
      if (err.response.data.msg == "token") {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
      console.log(err.response)
    })
  }

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

      clearInterval(checkTokenExpiration);
      getData();
      getDetails();
     getAds();
    

  }, []);
  const styles = [`
    .card0 {
      background-color : #2c89e0;
      color : #fff;
      padding : 30px;
      font-size : 15px;
      border-radius : 10px;
      text-align : center;
    }
    .card1 {
      background-color : #F1EFEF;
      color: black;
      padding : 30px;
      font-size : 15px;
      border-radius : 10px;
      text-align : center;
    }
    .card2 {
      background-color : #2c89e0;
      color : #fff;
      padding : 30px;
      font-size : 15px;
      border-radius : 10px;
      text-align : center;
    }
    .card3 {
      background-color : #091266;
      border-radius : 10px;
    }
    .card4 {
      background-color : #360606;
      border-radius : 10px;
    }
    .card5 {
      background-color : #3f8517;
      border-radius : 10px;
    }
    .marquee {
      font-size: 24px;
      color: #06603a;
      margin-bottom : 10px;
    }
  `]
  console.log(adsList)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
     autoplay: true,       
      autoplaySpeed: 3000,
  };

  // const adSlides = adsList.map((row, index) => (
  //   <div key={index} className="ad-slide">
  //     {row.picture ? (
  //       <div class="card" className="card3" style={{ width: "400px", height: "400px" }}>
  //         <img class="card-img-top" src={row.picture} alt="Card image" style={{ width: "400px", height: "400px" }} />
  //       </div>
  //     ) : null}
  //   </div>
  // ));

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const openModal = (ad) => {
    setSelectedAd(ad);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAd(null);
    setModalOpen(false);
  };

  const adSlides = adsList.concat(adsList).map((row, index) => (
    <div key={index} className="ad-slide" onClick={() => openModal(row)}>
      {row.picture ? (
        <div className="card" style={{ width: "300px", height: "300px" ,marginRight:"50px", marginLeft: "50px"}}>
          <img
            className="card-img-top"
            src={row.picture}
            alt="Card image"
            style={{ width: "300px", height: "300px" }}
          />
        </div>
      ) : null}
    </div>
  ));

  
  return (
    <>
    <style>{styles}</style>
    <ProfileCircle />
    <br/>
    <div className="App" style={{ width: "100%" }}>
    {/* <marquee>
          <div class="container">{adSlides}</div>
        </marquee>{" "}
        <br/> <br/> <br/> */}
        <Slider {...settings}>
          {adSlides}
        </Slider> <br/> <br/> <br/>
        <br /><br /> <br />

    <div class="container">
    <div class="col-md-4">
        <div class="card" className='card0' style={{width: "250px", height:"125px"}}>
    <div class="card-body">
      <h2 class="card-title">Total Company <i class="bi bi-people"></i></h2><br/>
      <h1 class="card-text">{company}</h1>
    </div>
  </div>
    </div><div class="col-md-4">
        <div class="card" className='card1' style={{width: "250px", height:'125px'}}>
    <div class="card-body">
      <h2 class="card-title">Total Employee <i class="bi bi-briefcase-fill"></i></h2><br/>
      <h1 class="card-text">{employee}</h1>
    </div>
  </div>
    </div>
<div class="col-md-4">
        <div class="card" className='card2' style={{width: "250px", height:'125px'}}>
    <div class="card-body">
      <h2 class="card-title">Total Visitors <i class="bi bi-alarm"></i></h2><br/>
      <h1 class="card-text">{visiter}</h1>
    </div>
  </div>
    </div></div>
    {loading ? (
        <table className='loader'><tbody><tr><td></td></tr></tbody></table>
      ) : (
      <DataTable
        title="Employee Request"
        columns={columns}
        data={data}
        pagination
        highlightOnHover
      />)}
    </div>

    <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Ad Popup"
      >
        {selectedAd && (
          <div className="modal-content">
            <img src={selectedAd.picture} alt="Ad" />
        <AiFillCloseCircle onClick={closeModal} className="modal-close"/>
          </div>
        )}
      </ReactModal>
    </>
  );
}

export default Appoinment;