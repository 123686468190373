import React from "react";
import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Company from "./pages/Company";
import Request from "./pages/Request";
import Offers from "./pages/Offers";
import Appoinment from "./pages/Appoinment";
import Login from "./Login";
import Employee from "./pages/Employee";
import Logout from "./Logout";
import Report from "./pages/Report";
import EmployeeProfile from "./pages/EmployeeProfile";
import { ToastContainer } from "react-toastify";
import RequestList from "./pages/RequestList";
import DoctorProfile from "./pages/DoctorProfile";
import Sliding from "./components/Sliding";
import ProfileCircle from "./pages/Profile";
import ForgotPassword from "./ForgotPassword";
import NewPassword from "./pages/NewPassword";
import NotFound from "./components/404";
import HospitalList from "./pages/HospitalList";
import Arputham from "./pages/NetworkHospitals/Arputham";
import RR from "./pages/NetworkHospitals/RR";
import Malar from "./pages/NetworkHospitals/Malar";
import DiyaHeart from "./pages/NetworkHospitals/DiyaHeart";
import Rajeswari from "./pages/NetworkHospitals/Rajeswari";
import Sun from "./pages/NetworkHospitals/Sunscans";
import Santo from "./pages/NetworkHospitals/Santo";
import Radhu from "./pages/NetworkHospitals/Radhu";
import Bedasta from "./pages/NetworkHospitals/Bedesta";
import Genga from "./pages/NetworkHospitals/Gengadental";
import Annai from "./pages/NetworkHospitals/Annai";

function App() {
  const isLogin = localStorage.getItem("isLogin");
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <Appoinment />{" "}
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/company"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Company />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/employee"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Employee />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/request"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Request />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/report"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Report />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/networkhospitals"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <HospitalList />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/offers"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Offers />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/logout"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <Logout />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/myprofile"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <DoctorProfile />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/employee_profile/:id"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <EmployeeProfile />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/get_booking_details/:id"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <RequestList />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route path="/forgot_password" element={<ForgotPassword />}></Route>
          <Route path="/new_password/:token" element={<NewPassword />}></Route>
          <Route path="*" element={<NotFound />}></Route>
          <Route
            path="/Arputham Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Arputham />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/RR Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <RR />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Malar Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Malar />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Diya Heart Center"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <DiyaHeart />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Rajashwari Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Rajeswari />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Sun Scans"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Sun />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Santho Scans"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Santo />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Radhu Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Radhu />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Bedasta Clinic"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Bedasta />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Genga Dental Care"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Genga />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Annai Eye Clinic"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Annai />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
