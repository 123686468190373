import React,  {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
// import data from '../data.json'
import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
import '../App.css'; 
import { Link } from 'react-router-dom';
import axiosIns from '../components/axiosIns';
import { faEye} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = [
  `
  .bt_view{ height: 2opx !important; margin-left: 20px; width: 20px !important; font-size: 14px; height: 30px; }
  .rdt_TableRow{ padding: 1%; }
  .marquee {
    font-size: 24px;
    color: #06603a;
    margin-bottom : 10px;
  }
`,
];
const addDropDown = (param) => {
  console.log(param);

  return (
    <>
      <div>
        <Link to={`/get_booking_details/${param._id}`}>
        <FontAwesomeIcon icon={faEye} className="bt_view" style={{color:'blue'}}/>
        </Link>
      </div>
    </>
  );
};

 const columns = [
  {
    name: "Name & Company",
    cell: (row) => (
      <div>
        {row.emp_ename} <br />
        <br /> ({row.company})
      </div>
    ),
    sortable: true,
  },
  {
    name : "Patient Name",
    selector : "patient_name",
    sortable : true,
  },
  // {
  //   name: 'Company',
  //   selector: 'company',
  //   sortable: 'true'
  // },
  {
    name: 'Doctor',
    selector: 'doctor',
    sortable: 'true',
  },
  {
    name : 'Service',
    selector: 'service',
    sortable:'true'
  },
  {
    name : 'Date',
    selector: (param) => new Date(param.date).toLocaleDateString(),
    sortable : true
  },
  {
    name :'Status',
    selector: (param)=>addDropDown(param)
  },
];

function Request() {
  const [data, setData] = useState([]);
  // const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  
  const getData = async()=>{
    // axios.defaults.headers.common['Authorization'] = userToken
    await axiosIns.post('/booking_request_list')
    .then((res) => {
      setData(res.data.data)
      setFilteredData(res.data.data);
      setLoading(false)
      console.log(data)
    }).catch((err)=>{
      if (err.response.data.msg == "token") {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
      console.log(err.response)
    })
  }

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

      clearInterval(checkTokenExpiration);
      getData();
  }, [])

  const [filteredData, setFilteredData] = useState(data);

    const filterData = (value) => {
      const lowerCaseValue = value.toLowerCase().trim();
      const filteredData = data.filter((item) =>
        item.emp_ename.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredData);
    };

    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (event) => {
      setSearchValue(event.target.value);
      filterData(event.target.value);
    };

  return (
    <>
    <style>{styles}</style>
    <div className="App" style={{width:"100%"}}>
      <div align='left'>
    <input style={{width :"30%",borderRadius:"50px",marginRight:"770px",padding:"20px"}}
            type="text"
            placeholder="Search Request by Name"
            value={searchValue}
            onChange={handleSearch}/></div>
    {loading ? (
        <table className='loader'><tbody><tr><td></td></tr></tbody></table>
      ) : (
      <DataTable
        title="Employee Request"
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
      />)}
    </div>
    </>
  );
}

export default Request;