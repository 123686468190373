import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "../components/Toastify";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBBtn,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import axiosIns from "../components/axiosIns";
import { colors } from "@mui/material";

export default function RequestList() {
  const styles = `
      p {
        font-size: 14px !important;
      }
      .row{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      .marquee {
        font-size: 24px;
        color: #06603a;
        margin-bottom : 10px;
      }
    `;

  const [loading, setLoading] = useState(true);
  const [BookingData, setData] = useState({
    picture : "",
    family_picture : "",
    company : "",
    service : "",
    emp_name : "",
    status : "",
    description : "",
    emp_id : "",
    age : "",
    mobile : "",
    appointment_for : "",
    email : "",
    job_role : "",
    relation_name : "",
    relation_age : "",
  });
  const {
    company,
    service,
    emp_name,
    status,
    description,
    picture,
    family_picture,
    emp_id,
    age,
    mobile,
    appointment_for,
    email ,
    job_role,
    relation_name,
    relation_age,
  } = BookingData;
  useEffect(() => {
    getData();
  }, []);
  const params = useParams();
  const getData = async () => {
    if (params.id) {
      return await axiosIns
        .post("/get_booking_details", { id: params.id })
        .then((res) => {
          console.log(res.data.data) 
          const resdata = res.data.data;
          BookingData.picture = resdata.employee_details.picture;
          BookingData.family_picture = resdata.employee_details.family_picture;
          BookingData.emp_name = resdata.employee_details.name;
          BookingData.company = resdata.company_name;
          BookingData.emp_id = resdata.employee_details.employee_id;
          BookingData.age = resdata.employee_details.age;
          BookingData.mobile =  resdata.employee_details.mobile;
          // BookingData.appointment_for = resdata.appointment_for;
          BookingData.appointment_for = resdata.appointment_for === "1" ? "Myself" : "Family Members";
          BookingData.email = resdata.employee_details.email;
          BookingData.job_role = resdata.employee_details.job_role;
          BookingData.relation_name = resdata.family_details.relation_name;
          BookingData.relation_age = resdata.family_details.relation_age;
          setData({...BookingData});
        })
        .catch((err) => {
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
          console.log(err.response);
        });
    } else ; 
  };
  

//   const handleCommentChange = (event) => {
//     setData({...BookingData, comments: event.target.value});
//   }

  const handleStatusChange = (value) => {
    setData((prevState) => ({ ...prevState, status: value }));
    };
    
    const handleCommentsChange = (value) => {
    setData((prevState) => ({ ...prevState, description: value }));
    };
    
    const handleUpdateClick = async () => {
    try {
    const response = await axiosIns.post("/booking_update", {
    id: params.id,
    status,
    description, //description
    });
    Alert ('succ', "Booking Updated Successfully");
    window.location.replace("/request");
    } catch (error) {
      Alert ("error", error);
    console.log(error);
    }
    };

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 1000);
    
          clearInterval(checkTokenExpiration);
    getData();
    }, []);
  return (
    <section>
      <style>{styles}</style>
      <h2>Employee Request</h2>
      <MDBContainer className="py-5"> 
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={picture ? picture : "/images/noimg.jpg"}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: "250px", height: "250px" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={family_picture ? family_picture : "/images/noimg.jpg"}
                  alt="avatar"
                  style={{ width: "80%", height: "250px", borderRadius: "0" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{emp_name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Age</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{age}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Appoinment For</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{appointment_for}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                {appointment_for === "Family Members" && (
                <>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Patient Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{relation_name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Patient Age</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{relation_age}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                </>
              )}
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Company</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{company}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Employee ID</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{emp_id}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{email}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Job Role</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {job_role}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol sm="3">
                        <MDBCardText>Status</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                    <select
                      className="form-select"
                      value={status}
                      onChange={(e) => handleStatusChange(e.target.value)}
                      style={{borderRadius : '10px', fontSize : '13px', width :'24%' }}
                    >
                        <option value="">Select status</option>
                        <option value="1" style={{ color :'orange' , borderRadius :'10px' }} >Pending</option>
                        <option value="2" style={{ color: "green" ,borderRadius : '10px'  }} >Approved</option>
                        <option value="3" style={{ color: "red" ,borderRadius : '20px' }} >Rejected</option>
                        </select>
                    </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                    <MDBCol sm="3">
                        <MDBCardText>Comments</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                        <textarea
                        className="form-control"
                        rows="3"
                        value={description}
                        onChange={(e) => handleCommentsChange(e.target.value)} ></textarea>
                    </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                  <MDBBtn onClick={handleUpdateClick} style={{fontSize :"13px"}}>Update</MDBBtn>
              </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
