import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// import data from '../data.json'
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link } from "react-router-dom";
import axiosIns from "../components/axiosIns";

const styles = [
  ` .marquee {
    font-size: 24px;
    color: #06603a;
    margin-bottom : 10px;
  }
  `,
];

const getProfile = (param) => {
  return (
    <>
      <img
        height={50}
        width={50}
        style={{ borderRadius: "10%", width: "85px", height: "100px" }}
        src={param.picture ? param.picture : "./images/noimg.jpg"}
      />
    </>
  );
};

const columns = [
  {
    name: "Name",
    selector: "company_name",
    sortable: true,
  },
  {
    name: "Logo",
    selector: (param) => getProfile(param),
    sortable: true,
  },
  {
    name: "Email Address",
    selector: "email",
    sortable: true,
  },
  {
    name: "Mobile",
    selector: "mobile",
    sortable: true,
  },
  {
    name: "Company Address",
    selector: "address",
    sortable: "true",
  },
  // {
  //   name :'Actions',
  //   selector :'actions',
  //   render:(<div><i className="bi bi-pencil-square"></i><i className="bi bi-trash3-fill"></i></div>)
  // },
];

function Company() {
  const [data, setData] = useState([]);
  // const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    // axios.defaults.headers.common['Authorization'] = userToken
    await axiosIns
      .post("/company_list")
      .then((res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
        console.log(data);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };
  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);

    const filterData = (value) => {
      const lowerCaseValue = value.toLowerCase().trim();
      const filteredData = data.filter((item) =>
        item.company_name.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredData);
    };

    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (event) => {
      setSearchValue(event.target.value);
      filterData(event.target.value);
    };

  return (
    <>
        <style>{styles}</style>
      <div className="App" style={{ width: "100%" }}>
        <div align='left'>
      <input style={{width :"30%",borderRadius:"50px",marginRight:"770px",padding:"20px"}}
            type="text"
            placeholder="Search Company by Name"
            value={searchValue}
            onChange={handleSearch}/>
            </div>
        {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <DataTable
            title="List of Company"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </>
  );
}

export default Company;
