import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

export default function Bedasta() {
  const styles = `
      p {
        font-size: 14px !important;
      }
      .row{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      .marquee {
        font-size: 24px;
        color: #06603a;
        margin-bottom : 10px;
      }
    `;
  
  

    // const params = useParams();
  return (
    <section>
      <style>{styles}</style>
      <h2 style={{textAlign:"center"}}>Bedasta Clinic</h2>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="8">
            <MDBCard className="mb-5">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={"/images/Bedestaclinic.jpg"}
                  alt="avatar"
                  style={{ width: "900px", height: "500px" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>&nbsp;
          <MDBCol lg="8">
            <MDBCard>
              <MDBRow>
                <h5>Hospital Address</h5>
                &nbsp;
                <p style={{textAlign:"center"}}> 5A5 , PATHINATHAPURAM Spinning Mill, opposite Thoothukudi, Tamil Nadu 628008</p>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
