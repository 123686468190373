import React from 'react';
import { FaExclamationCircle, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const styles = [`
.not-found-container {
    text-align: center;
    padding: 40px;
  }
  
  .not-found-title {
    font-size: 32px;
    color: #ff0000;
  }
  
  .not-found-message {
    font-size: 18px;
  }
  
  .not-found-icons {
    margin-top: 20px;
  }
  
  .not-found-icon {
    margin-right: 10px;
  }
  
  .not-found-image {
    margin-top: 10px;
  }
  .back-to-home {
    display: flex;
    align-items: center;
    margin-left : 685px;
    margin-top: 20px;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    font-size: 19px;
  }
  
  .arrow-icon {
    margin-right: 5px;
  }  
  
`]

const NotFound = () => {
  return (
    <>
    <style>{styles}</style>
    <div className="not-found-container">
      <h1 className="not-found-title">404 - Page Not Found</h1>
      <p className="not-found-message">The page you are looking for does not exist.</p>
      <div className="not-found-icons">
        <FaExclamationCircle className="not-found-icon" size={48} color="#ff0000" />
        <img className="not-found-image" src='/images/error.png' alt="Error" width={450} />
      </div>
      <br/>
      <Link to="/" className="back-to-home">
        <FaArrowLeft className="arrow-icon" />
        Back to Home
      </Link>
    </div>
    </>
  );
};

export default NotFound;
