import React, {useState,useEffect} from 'react';
import axiosIns from '../components/axiosIns';
import { Link } from 'react-router-dom';

const styles = [`
    .profile {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f7fffd;
  text-align: center;
  cursor: pointer;
}

.profile img {
  width: 50px;
  height: 60px;
  background-color : #f7fffd;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom : 30px;
}

`]

const ProfileCircle = () => {

    const [employeeData, setData] = useState({
        picture: "",
      });
      const {
        picture,
      } = employeeData;
    
      const ref_id = localStorage.getItem("Ref_id");
    
      //   const params = useParams();
      const getData = async () => {
        if (ref_id) {
          await axiosIns
            .post("/get_doctor", { id: ref_id })
            .then((res) => {
              setData(res.data.data);
            })
            .catch((err) => {
              if (err.response.data.msg == "token") {
                localStorage.clear("isLogin");
                window.location.reload("/");
              }
              console.log(err.response);
            });
        } //else window.location.replace("/");
      };
      useEffect(() => {
        const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 1000);
    
        clearInterval(checkTokenExpiration);
        getData();
      }, []);
    


  return (
    <>
    <style>{styles}</style>
    <div className="profile">
  <Link to={'/myprofile'}>
    <img src={picture ? picture : "/images/noimg.jpg"} alt="Profile" />
  </Link>
</div>
  </> 
  );
}

export default ProfileCircle;
