import React, { useState } from "react";

import {
  FaTh,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaSignOutAlt,
  FaTelegramPlane,
  FaPortrait,
  FaTags,
  // FaShoppingBag,
  FaThList,
  FaBars,
  FaUserCheck,
  FaHospitalAlt,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/",
      name: "Appoinment",
      pic: <FaTh />,
    },
    // {
    //   path:"/doctor",
    //   name:"Doctor",
    //   pic:<FaUserAlt/>
    // },
    {
      path: "/company",
      name: "Company",
      pic: <FaRegChartBar />,
    },
    {
      path: "/employee",
      name: "Employee",
      pic: <FaPortrait />,
    },
    {
      path: "/request",
      name: "Request",
      pic: <FaTelegramPlane />,
    },
    {
      path: "/report",
      name: "Report",
      pic: <FaCommentAlt />,
    },
    {
      path:"/networkhospitals",
      name:"Network Hospitals",
      pic:<FaHospitalAlt/>
    },
    // {
    //   path: "/myprofile",
    //   name : "My Profile",
    //   pic : <FaUserCheck/>,
    // },
    {
      path: "/logout",
      name: "Sign Out",
      pic: <FaSignOutAlt />,
    },
  ];
  // const logout = () => {
  //   localStorage.removeItem('token');
  //   isLogin(false);
  // };
  return (
    <div className="container">
      <div className="sidebar" style={{ width: isOpen ? "200px" : "53px" }}>
        <div className="top_section">
          <h1 className="logo" style={{ display: isOpen ? "block" : "none" }}>
            <img src="/Agan_logo.png" width={125} height={75} alt="logo" />
          </h1>
          <div className="bars" style={{ marginLeft: isOpen ? "0px" : "0px" }}>
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
          >
            <div className="icon">{item.pic}</div>
            <div
              className="link_text"
              style={{ display: isOpen ? "block" : "none" }}
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>

      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
