import axios from "axios";

const baseUrl = process.env.REACT_APP_URL
const token = localStorage.getItem('token');
const axiosIns = axios.create({
    baseURL : baseUrl,
    headers : {
        Authorization : token,
        "Content-Type" : "application/json"
    },
})

export default axiosIns;