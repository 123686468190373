import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import axiosIns from "../components/axiosIns";

export default function EmployeeProfile() {
  const styles = `
      p {
        font-size: 14px !important;
      }
      .row{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      .marquee {
        font-size: 24px;
        color: #06603a;
        margin-bottom : 10px;
      }
    `;

  const [loading, setLoading] = useState(true);
  const [employeeData, setData] = useState({
    picture: "",
    address: "",
    employee_id: "",
    gender: "",
    mobile: "",
    marital_status: "",
    email: "",
    age: "",
    name: "",
    job_role: "",
    family_picture: "",
    family_details: [],
  });
  const {
    picture,
    employee_id,
    job_role,
    address,
    mobile,
    marital_status,
    gender,
    email,
    name,
    age,
    family_picture,
    family_details,
  } = employeeData;
  useEffect(() => {
    getData();
  }, []);
  const params = useParams();
  const getData = async () => {
    if (params.id) {
      return await axiosIns
        .post("/get_employee", { id: params.id })
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
          console.log(err.response);
        });
    } else window.location.replace("/");
  };

  let family_list = family_details.map((row, i) => {
    return (
      <tr style={{ padding: "4px" }}>
        <td>{i + 1}</td>
        <td>{row.relation_name}</td>
        <td>{row.relationship}</td>
        <td>{row.relation_age}</td>
      </tr>
    );
  });
  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 1000);
    
          clearInterval(checkTokenExpiration);
    getData();
    }, []);
  return (
    <section>
      <style>{styles}</style>
      <h2>Employee Profile</h2>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={picture ? picture : "/images/noimg.jpg"}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: "250px", height: "250px" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={family_picture ? family_picture : "/images/noimg.jpg"}
                  alt="avatar"
                  style={{ width: "80%", height: "250px", borderRadius: "0" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Age</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{age}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{gender}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Employee Id</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {employee_id}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Job Role</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{job_role}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile Number</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mail Id</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{email}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Martial Status</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {marital_status}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{address}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="12" style={{ textAlign: "center" }}>
                    <MDBCardText>Family Members</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="12">
                    <table
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>RelationShip</th>
                          <th>Age</th>
                        </tr>
                      </thead>
                      <tbody>{family_list}</tbody>
                    </table>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
