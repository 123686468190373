import React, {useState, useEffect}from 'react';
import '../pages/Offers.css'
import axiosIns from '../components/axiosIns';

const Offers= () => {
    const [data, setData] = useState([]);
    // const REACT_APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL
    const getData = async()=>{
        await axiosIns.post('/offer_list')
        .then((res)=>{
            console.log(res)
            setData(res.data.data)
            console.log(data)
        }).catch((err)=>{
            if (err.response.data.msg == "token") {
                localStorage.clear("isLogin");
                window.location.reload("/");
              }
            console.log(err.response)
        })
    } 
    
    useEffect(()=>{
        const checkTokenExpiration = setInterval(() => {
            const expirationTime = localStorage.getItem("tokenExpirationTime");
            if (expirationTime && Date.now() > parseInt(expirationTime)) {
              localStorage.clear("isLogin");
              window.location.reload("/");
            }
          }, 1000);
      
            clearInterval(checkTokenExpiration);
            getData();
    }, [])
    console.log(data)
    const service = data.map((row) => {
      const styles = `
      .profile-card{
        width: 33% !important;
      }
      `;
        return (
            <div className="profile-card profile-radius">
                <div className="profile-content">
                <div className="desc">
                        <h5>{row.title}</h5>
                    </div>
                    <div className="profile-image">
                        <img className='img' style={{borderRadius: "0"}} src={row.picture ? row.picture : "/images/noimg.jpg" } alt="first user" />
                    </div>
                    <div className="desc">
                        <h5>{row.amount}</h5>
                        <p>{row.description}</p>
                    </div>
                </div>
            </div>
        )
    })
return(  
  <>
  
  <style></style>
    <div className="container1">
        <div className="team_container">  </div>
        <div className="row">
            {service}
        </div>    
    </div></>  
)
     
    
};

export default Offers;